
    <div>
        <div v-if="showDiscount" class="discount-container mt-4 mt-lg-0 mb-2 justify-content-end">
            <form class="d-flex discount-form w-100">
                <input v-model="discountCode" type="text" class="form-control inpunt-discount"
                       placeholder="Insert your discount code"
                       :disabled="status.discount || hasPendingCart || discountCodeFromIntent || discountFromInvite">
                <template v-if="!status.discount">
                    <button v-if="!discountCodeFromIntent && !discountFromInvite"
                            class="btn btn-warning btn-warning-cart btn-checkout"
                            @click.prevent="validateDiscountCode()">
                        Apply
                    </button>
                    <button v-else class="btn checkbox-discount" @click.prevent="removeDiscount()">
                        <img src="/public-assets/icon-check-discount-delete" alt="icon-check-discount-delete"
                             class="fake-image-verification ms-1">
                    </button>
                </template>
                <div v-else class="spinner-border" style="height: 30px; width: 30px;" role="status">
                    <span class="sr-only"></span>
                </div>
            </form>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <span class="order-span font-s-14">Subtotal</span>
            <span class="order-span font-s-14">${{ subtotal.toFixed(2) }}</span>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <span class="order-span font-s-14">You saved
<!--                <span v-if="discountFromInvite"> ({{ discountFromInvite.code }})</span>-->
            </span>
            <span class="order-span order-span-red font-s-14">-${{ Math.abs(discounted_prices).toFixed(2) }}</span>
        </div>
        <div class="d-flex justify-content-between mb-1" v-if="false">
            <span class="order-span font-s-14">Discount code</span>
            <span class="order-span order-span-red">-${{ discount }}.00</span>
        </div>
        <div class="d-flex justify-content-between" v-if="intent && intent.amount_paid > 0">
            <span class="order-span font-s-14">You already paid</span>
            <span class="order-span order-span-red">-${{ intent.amount_paid }}.00</span>
        </div>
        <div class="d-flex justify-content-between">
            <span class="order-span order-span-bold" style="color: white;">Total</span>
            <span class="order-span order-span-bold" style="color: white;">${{ total.toFixed(2) }}</span>
        </div>
        <StepManager ref="stepManager"
                     :contact="contact"
                     :products_count="products_count"
                     :storage="storage"
                     :status="status"
                     :config_data="config_data"
                     :hasPendingCart="hasPendingCart"
                     :discountApplied="discountType !== null"
                     @showDiscountField="showDiscountField"
                     @confirmOrder="confirmOrder"
                     @changeStatus="changeStatus"
                     @attachConfigData="attachConfigData"
                     @updateContactData="updateContactData"
                     @sendVoucher="sendVoucher"/>
    </div>
