export default {
    data: () => ({
        default_items: [
            {
                id: 'with-session-steps',
                steps: [
                    {
                        step_sequence: 1,
                        file: () => import ('./with-session-steps/PersonalInfo.vue'),
                        label: 'Personal Information'
                    },
                    // {
                    //     step_sequence: 2,
                    //     file: () => import ('./with-session-steps/deprecatedAddress.vue'),
                    //     label: 'Address'
                    // },
                    {
                        step_sequence: 2,
                        file: () => import ('./with-session-steps/MakePayment.vue'),
                        label: 'Payments'
                    }
                ]
            },
            {
                id: 'without-session-steps',
                steps: [
                    {
                        step_sequence: 1,
                        file: () => import ('./without-session-steps/VerifyEmail.vue'),
                        label: 'To continue enter your email to log in or register'
                    }
                ]
            }
        ],
        current_step: 1
    }),
    computed: {
        folderSubName() {
            if (!this.storage) return null;
            return this.storage === 'nsc' ? 'with' : 'without';
        },
        items() {
            return this.folderSubName && this.default_items ? this.default_items.find(x => x.id === `${this.folderSubName}-session-steps`) : [];
        },
        item() {
            return this.items.steps.find(x => x.step_sequence === this.current_step)
        }
    }
}