<template>
    <div>
        <div v-if="showDiscount" class="discount-container mt-4 mt-lg-0 mb-2 justify-content-end">
            <form class="d-flex discount-form w-100">
                <input v-model="discountCode" type="text" class="form-control inpunt-discount"
                       placeholder="Insert your discount code"
                       :disabled="status.discount || hasPendingCart || discountCodeFromIntent || discountFromInvite">
                <template v-if="!status.discount">
                    <button v-if="!discountCodeFromIntent && !discountFromInvite"
                            class="btn btn-warning btn-warning-cart btn-checkout"
                            @click.prevent="validateDiscountCode()">
                        Apply
                    </button>
                    <button v-else class="btn checkbox-discount" @click.prevent="removeDiscount()">
                        <img src="/public-assets/icon-check-discount-delete" alt="icon-check-discount-delete"
                             class="fake-image-verification ms-1">
                    </button>
                </template>
                <div v-else class="spinner-border" style="height: 30px; width: 30px;" role="status">
                    <span class="sr-only"></span>
                </div>
            </form>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <span class="order-span font-s-14">Subtotal</span>
            <span class="order-span font-s-14">${{ subtotal.toFixed(2) }}</span>
        </div>
        <div class="d-flex justify-content-between mb-1">
            <span class="order-span font-s-14">You saved
<!--                <span v-if="discountFromInvite"> ({{ discountFromInvite.code }})</span>-->
            </span>
            <span class="order-span order-span-red font-s-14">-${{ Math.abs(discounted_prices).toFixed(2) }}</span>
        </div>
        <div class="d-flex justify-content-between mb-1" v-if="false">
            <span class="order-span font-s-14">Discount code</span>
            <span class="order-span order-span-red">-${{ discount }}.00</span>
        </div>
        <div class="d-flex justify-content-between" v-if="intent && intent.amount_paid > 0">
            <span class="order-span font-s-14">You already paid</span>
            <span class="order-span order-span-red">-${{ intent.amount_paid }}.00</span>
        </div>
        <div class="d-flex justify-content-between">
            <span class="order-span order-span-bold" style="color: white;">Total</span>
            <span class="order-span order-span-bold" style="color: white;">${{ total.toFixed(2) }}</span>
        </div>
        <StepManager ref="stepManager"
                     :contact="contact"
                     :products_count="products_count"
                     :storage="storage"
                     :status="status"
                     :config_data="config_data"
                     :hasPendingCart="hasPendingCart"
                     :discountApplied="discountType !== null"
                     @showDiscountField="showDiscountField"
                     @confirmOrder="confirmOrder"
                     @changeStatus="changeStatus"
                     @attachConfigData="attachConfigData"
                     @updateContactData="updateContactData"
                     @sendVoucher="sendVoucher"/>
    </div>
</template>

<script>
import StepManager from './StepManager.vue';
import prices from './prices-config';

export default {
    props: {
        order: Object,
        intent: Object,
        contact: Object,
        storage: String,
        products_count: Number,
        hasPendingCart: Boolean,
        discountCodeFromIntent: String,
        showDiscountProp: Boolean,
        discountFromInvite: Object,
    },
    mixins: [prices],
    components: {StepManager},
    data: () => ({
        discount: 0,
        discountCode: '',
        showDiscount: false,
        status: {
            confirm: false,
            discount: false
        },
        config_data: {
            total_price: 0,
            payments: {clientId: 'test'},
            contact: null
        },
    }),
    mounted() {
        this.discountCode = this.discountCodeFromIntent
        this.showDiscount = this.showDiscountProp

        if (!this.discountCodeFromIntent) {
            let urlParams = new URLSearchParams(window.location.search);
            let discountCode = urlParams.get('discount');
            if (discountCode) {
                this.discountCode = discountCode
            }
        }
    },
    computed: {
        products() {
            return this.order?.relationships?.order_items_groups;
        },
        discounted_prices() {
            if (this.discountFromInvite) {
                // Product that is used in the discount code
                let discountProduct = this.products.find(p => p.sku_id === this.discountFromInvite.sku_id)
                // Normal product price
                let vol_discount_price = discountProduct.sku.price
                // Normal product price, used to find, if exists, volume discount
                let vol_price_found = discountProduct.sku.price

                if (discountProduct.sku.volume_prices) {
                    let sorted_volume_prices = discountProduct.sku.volume_prices.sort((a, b) => parseInt(a.quantity) - parseInt(b.quantity))
                    let volume_price = sorted_volume_prices.findLast((vp) => discountProduct.quantity >= parseInt(vp.quantity))
                    vol_price_found = volume_price ? volume_price.price : discountProduct.sku.price
                }

                let is_ranged = Array.isArray(this.discountFromInvite.ranges) && this.discountFromInvite.ranges.length > 0

                if (is_ranged) {
                    for (const range of this.discountFromInvite.ranges) {
                        if (parseInt(discountProduct.quantity) >= range.from) {
                            let is_percentage_range = range.discount.includes('%')
                            let discount_amount_range = is_percentage_range ? Number(range.discount.replace('%', '')) : range.discount
                            vol_discount_price = is_percentage_range ? vol_price_found * (discount_amount_range / 100) : discount_amount_range
                        }
                    }
                } else {
                    let is_percentage = this.discountFromInvite.discount.includes('%')
                    let discount_amount = is_percentage ? Number(this.discountFromInvite.discount.replace('%', '')) : this.discountFromInvite.discount
                    vol_discount_price = is_percentage ? vol_price_found * (discount_amount / 100) : discount_amount
                }

                let lowest_price = Math.min(vol_discount_price, vol_price_found)
                let total_discount = lowest_price.toFixed(2) * parseInt(discountProduct.quantity)

                if (!this.discountFromInvite.all_licences && !is_ranged){
                    total_discount = lowest_price
                }

                return total_discount
            }

            return this.intent?.discounts || 0;
        },
        discountType() {
            return this.intent?.data?.discount_data?.type || null;
        },
        subtotal() {
            if (this.products == null) return 0;
            let subtotal = 0;
            let copy_products = JSON.parse(JSON.stringify(this.products))
            copy_products.forEach(product => {
                if (Array.isArray(product.sku.volume_prices) && product.on_sale_price > 0) {
                    let copiedPrices = JSON.parse(JSON.stringify(product.sku.volume_prices))
                    let sortedPrices = copiedPrices.sort((a, b) => b.quantity - a.quantity)
                    let hasDiscount = sortedPrices.find(v => parseInt(product.quantity) >= v.quantity);

                    if (hasDiscount) {
                        subtotal += (product.quantity * hasDiscount.price);
                    } else {
                        subtotal += (product.on_sale_price * product.quantity)
                    }
                } else {
                    subtotal += (product.on_sale_price * product.quantity)
                }
            });
            return subtotal;
        },
        total() {
            return this.subtotal - Math.abs(this.discounted_prices) - Math.abs(this.intent.amount_paid || 0);
        },
    },
    watch: {
        total: {
            handler: function (newValue) {
                this.config_data.total_price = newValue
                this.$emit("updateTotal", newValue)
            },
            immediate: true
        },
        contact: {
            handler: function (newValue) {
                if (newValue) {
                    this.config_data.contact = newValue;
                }
            },
            immediate: true
        },
        discountCodeFromIntent: {
            handler: function (newValue) {
                this.discountCode = newValue
            },
            immediate: false
        },
    },
    methods: {
        showDiscountField(payload) {
            this.showDiscount = payload
        },
        updateContactData(payload) {
            this.$emit('updateContactData', payload)
        },
        changeStatus({key, value}) {
            this.status[key] = value;
        },
        async confirmOrder(payload) {
            if (this.discountFromInvite) {
                await this.validateDiscountCode()
            }

            this.status.confirm = true
            let data = {
                ...payload,
                'address': this.config_data.currentAdress
            }
            this.$axios.post('/api/confirm-cart', {data}).then(response => {
                if (response.data.error) {
                    if (response.data.intent) {
                        this.$refs.stepManager.onErrorInConfirm(response.data.intent)
                    }
                    this.$swal('Error..', response.data.error, 'error')
                } else if (response?.data?.data?.order_status_id === 5) {
                    this.$swal('Order confirmed!', 'Your order has been confirmed successfully.', 'success')
                    this.$emit('orderCreated', {config: this.config_data, sale_order: response.data.data})
                } else if (response.data.amount_paid < response.data.amount_to_pay) {
                    this.$swal('Payment confirmed!', 'Your payment has been confirmed successfully.', 'success')
                    this.$emit('orderUpdated', {intent: response.data})
                }
            }).catch(error => {
                this.$swal('Oops..', 'Something was wrong', 'error')
            }).finally(() => {
                this.status.confirm = false
            })
        },
        sendVoucher(voucher) {
            let payload = {
                type: 'voucher',
                data: voucher
            }
            this.confirmOrder(payload)
        },
        attachConfigData(payload) {
            this.$set(this.config_data, payload.key, payload.value);
        },
        async validateDiscountCode() {
            if (!this.discountCode) {
                this.$swal('Error', "Enter a discount code", 'error')
                return
            }

            if (this.status.discount || this.hasPendingCart) return;
            this.status.discount = true

            await this.$axios.post(`/api/discounts/apply/${this.discountCode}`).then(response => {
                let data = response.data.data

                if (data.error) {
                    this.$swal('Error..', data.error, 'error')
                } else {
                    this.$emit('orderUpdated', {intent: data})
                    if (!this.discountFromInvite) {
                        this.$swal('Discount applied!', "Your discount code has been applied successfully.", 'success').then(() => {
                            window.location.reload()
                        })
                    }
                }
            }).catch(error => {
                this.$swal('Discount code error!', "The discount code you entered is not valid, please try again with a different code.", 'error')
            }).finally(() => {
                this.status.discount = false
            })
        },
        removeDiscount() {
            if (this.discountFromInvite) {
                window.location.href = '/checkout'
                return
            }

            if (this.status.discount) return;

            this.status.discount = true

            this.$axios.post(`/api/discounts/remove/${this.discountCode}`).then(response => {
                let data = response.data.data

                if (data.error) {
                    this.$swal('Error..', data.error, 'error')
                } else {
                    this.$emit('orderUpdated', {intent: {}})
                    this.$swal('Success..', "The discount has been removed", 'success').then(() => {
                        window.location.reload()
                    })
                }
            }).catch(error => {
                this.$swal('Discount code error!', "The discount code you entered is not valid, please try again with a different code.", 'error')
            }).finally(() => {
                this.status.discount = false
            })
        },
    }
}
</script>

<style>
.order-span {
    font-size: 14px;
    margin: 1px 0;
    color: #A7A7A7;
}

.order-span-red {
    color: red !important;
}

.order-span-bold {
    font-weight: 700;
    font-size: 15px;
}

.inpunt-discount {
    height: 30px;
}

.btn-checkout {
    height: 30px !important;
    font-size: 10px;
}
</style>