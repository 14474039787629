
<div class="my-4" v-if="products_count > 0">
    <div class="steps-bar d-flex">
        <div class="text-left step-title d-flex" :class="{'col-md-10': !isSingleItem, 'col-md-12': isSingleItem}">
            <i v-if="current_step > 1" class="back-btn fas fa-chevron-left"
               @click.prevent="changeStep(current_step - 1)"></i>
            <label>{{ item.label }}</label>
        </div>
        <div v-if="!isSingleItem" class="col-md-2 text-center step-count" style="font-size: 15px">
            {{ item.step_sequence }}/{{ items ? items.steps.length : '--' }}
        </div>
    </div>
    <component ref="component"
               v-bind:is="item.file"
               :contact="contact"
               :status="status"
               :config_data="config_data"
               :hasPendingCart="hasPendingCart"
               :extraData="extraData"
               @showDiscountField="showDiscountField"
               @changeStep="changeStep"
               @contactNotFound="contactNotFound"
               @customHook="customHook"
               @sendVoucher="sendVoucher"/>
</div>
