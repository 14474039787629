import _ from 'lodash';
import Number from '/components/pub/helpers/fields/Number.vue';
import prices from './prices-config';
import cartStore from '@/components/pub/mixins/cartStore';
import {mapState} from 'vuex';
import 'sweetalert2/dist/sweetalert2.min.css';

let controller;
export default {
    store: cartStore,
    props: {
        product: Object,
        customId: Number,
    },
    mixins: [prices],
    components: {Number},
    data: () => ({
        status: {
            delete: false
        },
        storage: 'default',
        hasVolumeDiscount: false,
        ranges : []
    }),
    mounted() {
        this.hasVolumeDiscount = this.discountData && this.discountData.sku_id === this.product.sku_id && this.discountData.ranges?.length > 0
        if (this.hasVolumeDiscount) {
            this.ranges = this.prepareRanges(this.discountData.ranges)
        }
    },
    computed: {
        ...mapState(['intent']),
        hasImage() {
            return this.product?.sku?.product?.versions.find(v => v.content_version_id === 2)?.product_image
        },
    },
    watch: {
        'product.quantity': {
            handler: function (newValue, oldValue) {
                if (newValue > 0) {
                    const default_volume_discounts = [{quantity: 1, price: this.product.sku.price}];
                    const product_volume_discounts = this.product.sku.volume_prices || []

                    this.current_price = this.getCurrentPrice(
                        default_volume_discounts.concat(product_volume_discounts),
                        newValue,
                        this.product.sku.price,
                        this.product.quantity
                    );
                    if (oldValue) this.updateProduct();
                } else {
                    this.$set(this.product, 'quantity', 1);
                }
                if (this.product.quantity > 999) {
                    this.$set(this.product, 'quantity', 999);
                }
            },
            immediate: true
        },
        'product.assing_one': {
            handler: function (newValue, oldValue) {
                this.assignOne();
            }
        }
    },
    methods: {
        remove() {
            if (this.hasPendingCart) return;

            if (!this.status.delete) {
                this.status.delete = true;
                if (controller) controller.abort()

                this.$swal.fire({
                    title: 'Do you want to remove the item from cart?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$axios.delete(`/api/cart/${this.product.id}?skuId=${this.product.sku_id}`).then(r => {
                            if (r.data.error) {
                                this.$swal('Error..', r.data.error, 'error')
                            } else {
                                if (r.data.discount) this.$emit('orderUpdated', {intent: {}})
                                this.$emit('removedProduct', this.product.id);
                            }
                        }).finally(() => {
                            this.status.delete = false;
                        })
                    } else {
                        this.status.delete = false;
                    }
                })
            }
        },
        updateProduct: _.debounce(function () {
            if (controller) controller.abort()

            controller = new AbortController()
            this.$axios.put(`/api/cart/${this.product.id}`, {
                data: {
                    quantity: this.product.quantity,
                },
                signal: controller.signal
            }).then(() => {
                this.product.subtotal = parseInt(this.product.quantity) * parseInt(this.product.on_sale_price);
                this.$emit('updatedProduct', this.product);
            }).catch(error => {
                console.warn(error)
                this.$swal('Oops..', 'Something was wrong', 'error');
            })
        }, 1000),
        assignOne: _.debounce(function () {
            this.$axios.put(`/api/cart/assign-one/${this.product.id}`, {
                data: {
                    assing_one: this.product.assing_one
                }
            }).then(() => {
            }).catch(error => {
                console.warn(error)
                this.$swal('Oops..', 'Something was wrong', 'error');
            })
        }, 1000),
        prepareRanges(ranges) {
            // let total = this.product.quantity * this.product.sku.price
            let total = this.product.quantity * this.verifyCurrentPrice()
            return ranges.map(range => {
                let is_percentual = range.discount.includes('%')
                let discount_amount = is_percentual ? range.discount.replace('%', '') : range.discount
                return {
                    quantity: range.from,
                    to: range.to,
                    discount: range.discount,
                    // price: is_percentual ? total * (1 - (discount_amount / 100)) / this.product.quantity : this.product.sku.price - range.discount,
                    price: is_percentual ? total * (1 - (discount_amount / 100)) / this.product.quantity : this.verifyCurrentPrice() - range.discount,
                }
            })
        },
        verifyCurrentPrice() {
            let discount = this.ranges.find(range => {
                return this.product.quantity >= parseInt(range.quantity) && (!range.to || this.product.quantity <= range.to)
            })
            return discount && discount.price < this.current_price.price ? discount.price : this.current_price.price
        },
        verifyDiscountSku(discountFromInvite) {
            if (discountFromInvite) return false
            let isValid = false
            if (this.discountData && this.discountData.sku_id === this.product.sku_id) {
                isValid = true
            }
            return isValid
        }
    }
}