<template>
    <div class="my-4" v-if="products_count > 0">
        <div class="steps-bar d-flex">
            <div class="text-left step-title d-flex" :class="{'col-md-10': !isSingleItem, 'col-md-12': isSingleItem}">
                <i v-if="current_step > 1" class="back-btn fas fa-chevron-left"
                   @click.prevent="changeStep(current_step - 1)"></i>
                <label>{{ item.label }}</label>
            </div>
            <div v-if="!isSingleItem" class="col-md-2 text-center step-count" style="font-size: 15px">
                {{ item.step_sequence }}/{{ items ? items.steps.length : '--' }}
            </div>
        </div>
        <component ref="component"
                   v-bind:is="item.file"
                   :contact="contact"
                   :status="status"
                   :config_data="config_data"
                   :hasPendingCart="hasPendingCart"
                   :extraData="extraData"
                   @showDiscountField="showDiscountField"
                   @changeStep="changeStep"
                   @contactNotFound="contactNotFound"
                   @customHook="customHook"
                   @sendVoucher="sendVoucher"/>
    </div>
</template>

<script>
import Steps from './steps.js';

export default {
    props: {
        storage: String,
        products_count: Number,
        contact: Object,
        status: Object,
        config_data: Object,
        hasPendingCart: Boolean,
        discountApplied: Boolean,
    },
    mixins: [Steps],
    data: () => ({extraData: null}),
    computed: {
        isSingleItem() {
            return this.items && this.items.steps && this.items.steps.length === 1
        }
    },
    methods: {
        customHook(name, payload = null) {
            this.$emit(name, payload);
        },
        sendVoucher(voucher) {
            this.$emit('sendVoucher', voucher)
        },
        changeStep(step_id, extraData = null) {
            if (step_id) this.current_step = step_id;
            else this.current_step += 1;

            this.showDiscountField(this.current_step === 2 && this.folderSubName === 'with')

            if (extraData) {
                if (extraData.terms_and_conditions) {
                    this.$emit('updateContactData', {
                        key: 'terms_and_conditions',
                        payload: extraData.terms_and_conditions
                    })
                } else {
                    this.extraData = extraData
                }
            }
        },
        showDiscountField(payload){
            this.$emit('showDiscountField', payload)
        },
        contactNotFound(email, step = null) {
            this.$emit('attachConfigData', {key: 'contact', value: {email: email}});

            let newTabs = {
                id: 'without-session-steps',
                steps: [
                    {
                        step_sequence: 1,
                        file: () => import ('./without-session-steps/RegisterCoreAttributes.vue'),
                        label: 'Register'
                    },
                    // {
                    //     step_sequence: 2,
                    //     file: () => import ('./without-session-steps/RegisterAddress.vue'),
                    //     label: 'Address'
                    // },
                    {
                        step_sequence: 2,
                        file: () => import ('./without-session-steps/VerifyEmail.vue'),
                        label: 'Email has been sent'
                    }
                ]
            }
            this.default_items.splice(1, 1, newTabs);

            if (step) {
                this.current_step = step;
            }
        },
        onErrorInConfirm(payIntent) {
            this.$refs.component.onErrorInConfirm(payIntent)
        }
    },
    mounted() {
        if (this.discountApplied) {
            this.current_step = 2
            this.showDiscountField(this.current_step === 2)
        }
    }
}
</script>

<style lang="scss" scoped>
.steps-bar {
    width: 100%;
}

.step-title {
    background-color: #2c2c2c;
    color: #fff;
    padding: 0.3rem;
    min-height: 35px;
}

.step-title label {
    padding-left: 5px;
}

.step-count {
    padding-top: 7px;
    background-color: #D1D1D1;
    color: #000000;
    font-weight: 500;
}

.back-btn {
    cursor: pointer;
    padding: .3rem .75rem;
    color: #C83232;
}

.col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-md-12 {
    flex: 0 0 auto;
    width: 100%
}

.col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}
</style>