export default {
    data: () => ({
        current_price: null
    }),
    methods: {
        getCurrentPrice(volume_discounts, newSeats, unit_price, new_quantity = 1) {
            if (unit_price <= 0) return { price: unit_price, quantity: new_quantity };
            
            return volume_discounts.reduce((carry, price) => {
                return price.quantity <= parseInt(newSeats) ? { price: price.price, quantity: new_quantity } : carry
            }, {  price: unit_price, quantity: new_quantity })
        }
    }
}